const images = [
    {
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1280px-React-icon.svg.png",
        title: "React", 
        description: "React on JavaScript framework",
        id: 0,
        price: 10,
        amount: 0
    },
    {
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/800px-Angular_full_color_logo.svg.png",
        title: "Angular", 
        description: "Angular on JavaScript framework",
        id: 1,
        price: 10,
        amount: 0
    },
    {
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Vue.js_Logo_2.svg/1024px-Vue.js_Logo_2.svg.png",        imgTitle: "Angular", 
        title: "Vue", 
        description: "Vue on JavaScript framework",
        id: 2,
        price: 10,
        amount: 0
    }
]

export default images;