import React from 'react';
import '../App.css';

const Row = ({course}) => {
    return (
    <tr>
        <td>{course.course}</td>
        <td>{course.teacher}</td>
        <td>{course.class}</td>
    </tr>
    )
}

const CourseInfo = (props) => {
    return (
        <table>
            <tbody>
            {props.course.map(c => (
                <Row course = {c} key = {c.id} />
            ))}
            </tbody>
        </table>
    )
}

export default CourseInfo;