import React from 'react';
import '../App.css';

const ListItem = (props) => {
  return (
    <li>{props.text}</li>
  )
}

const Course = (props) => {
  if (props.show) {
    return (
      <div>
        <h1>{props.partName}</h1>
        <h1>{props.partDate}</h1>
        <ul>
          {props.partContents.map(t => (
            <ListItem text={t} key={props.partContents.indexOf(t)} />
          ))}
        </ul>
      </div>
    )
  } else {
    return <div />
  }
}


export default Course;