import React, { useState } from 'react'
import '../App.css';

import images from './ImageData.js';
import ImagesInfo from './ImagesInfo.js';
import UserInfo from './UserInfo.js';
import Summary from './Summary.js';

const Store = () => {
    const [myImages, setMyImages] = useState(images);
    const [newInfo, setNewInfo] = useState({name: "", adress:""});
    const [payment, goToPayment] = useState(false);

    const amounts = myImages.map(img => img.amount * img.price);
    const total = amounts.reduce((a, b) => a + b, 0);
  
    // const setToZero = (img) => {
    //   const tempImg = {...img};
    //   tempImg.amount = 0;
    //   return tempImg;
    // }
  
    // const setToZeros = () => {
    //     const tempImages = images.map(img => setToZero(img));
    //     setMyImages(tempImages);
    // }
    const setToZeros = () => {
        const tempImages = images.map(img => {
            const tempImg = {...img, amount: 0};
            return tempImg;
        });
        setMyImages(tempImages);
    }

    const infoChangeHandler = (event, field) => {
        const tempInfo = {...newInfo};
        tempInfo[field] = event.target.value;
        setNewInfo(tempInfo);
      } 
    
      const infoSubmitHandler = event => {
        event.preventDefault();
        // tarkista että kaikki tiedot ok
        console.log("nimi: ", newInfo.name, "address: ", newInfo.address);
        // jos ok niin nollaa:
        setNewInfo({name: "", address:""});
      }

    if(!payment){
      return (
        <div className='container'> 
            <ImagesInfo images={myImages} updateImages={setMyImages}  />
            <Summary images={myImages} updateImages={setMyImages} setToZeros={setToZeros} goToPayment={goToPayment} total={total} />
        </div> 
        )} else {
        return (
          <div className='container'> 
            <Summary images={myImages} updateImages={setMyImages} setToZeros={setToZeros} goToPayment={goToPayment} total={total}/>
            <UserInfo newInfo={newInfo} changeHandler={infoChangeHandler} 
                submitHandler={infoSubmitHandler}></UserInfo>
          </div> 
        )}
    }

export default Store;

