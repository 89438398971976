import React, { useState } from 'react'
import '../App.css';

const SummaryInfo = ({imageData}) => {
    //console.log(imageData.title);
        return (
            <div>
                <h1>{imageData.title}</h1>
                <p>Price: {imageData.price}</p>
                <p>Amount: {imageData.amount}</p>
                <p>Total: {imageData.price * imageData.amount}</p>
            </div>
        )
}

const Summary = ({images, total, setToZeros, goToPayment}) => {

    return (
        <div>
            <h1>Tähän se tulee</h1>
            {images.map(img => (
            <SummaryInfo imageData={img} key={img.id} /> 
            ))} 
        <h2>Tykkäyksiä: {total}</h2>
        <button onClick={goToPayment}>Kassalle</button>
        <button onClick={setToZeros}>Nollaa</button>
        </div>
    )
}

export default Summary;