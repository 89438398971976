import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import logo from './logo.svg';
import './App.css';
import Course from './components/Course';
import CourseInfo from './components/CourseInfo';
import Skills from './components/Skills';
import ToggleButton from './components/ToggleButton';
import Store from './components/Store.js';

const coursePart = "React alkeet, osa 1";
const courseDate ="12.8.2019";
const courseContents = ["create react app", "gitbash", "JSX", "DOM"];

const course1 = {id:0, course: "JavaScript", teacher: "Tiina Partanen", class: "2074"};
const course2 = {id:1, course: "Java", teacher: "Eerikki Maula", class: "2069"};
const course3 = {id:2, course: "Unity", teacher: "Eerikki Maula", class: "2067"};

const courses = [course1, course2, course3];
const initialSkills = ["JavaScript", "PHP", "Flexbox"];

const App = () => {
  // state
  const [show, setShow] = useState([true, true, true]);
  const [skills, setSkills] = useState(initialSkills);
  const [newSkill, setNewSkill] = useState("");
  
  const handleClick = numberOfButton => {
    let tempShow = [...show];
    tempShow[numberOfButton] = tempShow[numberOfButton] ? false : true;
    setShow(tempShow);   // HUOM tässä vasta muutetaan! 
  }

  const handleChange = event => {
    setNewSkill(event.target.value);
    //console.log(newSkill);
  }

  const handleSubmit = event => {
    event.preventDefault();
    let tempSkills = skills.concat(newSkill);
    setSkills(tempSkills);
    setNewSkill("");
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>HIP HEI!</h1>
      </header>

        <Course show={show[0]} partName={coursePart} partDate={courseDate} partContents={courseContents}/>
        <ToggleButton show={show[0]} clickHandler={handleClick}  buttonNumber={0} />
        <Course partName="React alkeet 2" partDate="12.8.2019" partContents={courseContents} />
        <CourseInfo course={courses} />
        <Skills newSkill={newSkill} 
                skills={skills} 
                changeHandler={e => handleChange(e)} 
                submitHandler={e => handleSubmit(e)}/>
   
        <Store />
      
      <footer>
        <p>
          Tämä on hienointa ikinä!!!
        </p>
        <a
          className="App-link"
          href="https://is.fi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lue päivän lehti :-)
        </a>
      </footer>  
    </div>
  );
}

export default App;
